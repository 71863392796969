import { metrics, trace } from '@opentelemetry/api'
import { ZoneContextManager } from '@opentelemetry/context-zone'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load'
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch'
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request'
import { browserDetector } from '@opentelemetry/opentelemetry-browser-detector'
import { detectResourcesSync, Resource } from '@opentelemetry/resources'
import { MeterProvider } from '@opentelemetry/sdk-metrics'
import { BatchSpanProcessor, WebTracerProvider } from '@opentelemetry/sdk-trace-web'

const resource = detectResourcesSync({ detectors: [browserDetector] }).merge(new Resource({
  ...window.__appConfig.telemetry.resource,
}))

const headers = Object.keys(__appConfig.telemetry.headers).length
  ? { ...window.__appConfig.telemetry.headers }
  : undefined

window.tracerProvider = new WebTracerProvider({
  resource,
  spanProcessors: new BatchSpanProcessor(new OTLPTraceExporter({
    url: `${window.__appConfig.telemetry.collectorUrl.replace(/\/$/, '')}/v1/traces`,
    headers,
  })),
})

tracerProvider.register({
  contextManager: new ZoneContextManager(),
})
trace.setGlobalTracerProvider(tracerProvider)

window.meterProvider = new MeterProvider({ resource })
metrics.setGlobalMeterProvider(meterProvider)

registerInstrumentations({
  instrumentations: [
    new DocumentLoadInstrumentation(),
    new FetchInstrumentation(),
    new XMLHttpRequestInstrumentation(),
  ],
  meterProvider,
  tracerProvider,
})
